import { ref, watch, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import zaloStoreModule from '../zaloStoreModule';

export default function useZaloList() {
  const STORE_MODULE_NAME = 'zalo';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, zaloStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    id: 0,
    name: '',
  };
  const { t } = useI18nUtils();
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    // {
    //   label: t('Mã'),
    //   field: 'code',
    // },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Mô tả'),
      field: 'description',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      sortable: false,
      width: '100px',
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchZalos = () => {
    store
      .dispatch('zalo/fetchZalos', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const getLinkURL = () => {
    store
      .dispatch('zalo/getZaloLinkURL', serverParams.value)
      .then(response => {
        window.location.href = response.data
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  }




  const refetchZalos = () => {
    fetchZalos();
  };

  const deleteZalos = zalos => {
    store
      .dispatch('zalo/deleteZalos', {
        ids: zalos.map(_obj => _obj.id),
      })
      .then(() => {
        fetchZalos();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createZalo = () => {
    store
      .dispatch('zalo/createZalo', {
        name: item.value.name,
      })
      .then(() => {
        fetchZalos();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchZalos();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchZalos,
    fetchZalos,
    deleteZalos,
    createZalo,
    getLinkURL,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    t,
  };
}
